<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack }"
        title="Редактирование работы"
        update
        url-update="/job/update/:id"
        url-back="/job"
        action-one="job/one"
    >
        <job-form :model="model" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import JobForm from '@/components/forms/JobForm.vue'
export default {
    name: 'JobUpdate',
    components: { FormView, JobForm }
}
</script>