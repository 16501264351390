<template>
    <div class="d-flex flex-row align-center">

        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                >+ {{label}}</v-btn>
            </template>
            <ml-manager
                v-model="dialog"
                :files="filesToShow"
                :pickedFilePath.sync="localValue"
                :loading="loading"
                :label="label"
                :path="path"
                :type="type"
                :showUploadMoreButton="showUploadMoreButton"
                window-view
                @onFileSelect="returnPath"
                @uploaded="fetchFiles"
                @scroll-ended="fetchFiles"
                @add-new-items-to-list="addNewItemsToList"
                @search="onSearch"
            />
        </v-dialog>

        <div
            v-if="localValue"
            class="ml-4"
        >
            <div
                v-if="isValueFoundInFiles"
                class="d-flex flex-row align-center"
            >
                <img
                    v-if="getFileType(localValue) === 'image'"
                    :src="`${origin}/${localValue}`"
                    alt="Preview"
                    class="media-lib__preview-image media-lib__preview-image--small-square"
                >
                <audio
                    v-else-if="getFileType(localValue) === 'audio'"
                    :src="`${origin}/${localValue}`"
                    controls
                ></audio>

                <span v-else>
                    {{ localValue.split('/').pop() }}
                </span>

                <v-icon class="ml-2" title="Отменить выбор" color="red" @click="returnPath(null)">mdi-close</v-icon>
            </div>
            <v-alert v-else type="error" class="text-center">
                Файл {{localValue}} не найден
            </v-alert>
        </div>
    </div>
</template>
<script>
import mlManager from './ml-manager'

export default {
    components: { mlManager },
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        value: { type: String, default: null },
        label: { type: String, default: 'Файл' },
        path: { type: String, default: '' },
        type: { type: String, default: null }
    },
    data () {
        return {
            onceChanged: false, // Флаг означающий, что значение компонента было изменено вручную хотя бы 1 раз
            dialog: false,
            loading: false,
            files: [],
            foundItems: [],
            lastSearchPhrase: '',
            showUploadMoreButton: true,
            localValue: null
        }
    },
    watch: {
        value: {
            handler (val) {
                this.localValue = val
            }
        }
    },
    computed: {
        origin () {
            return window.location.origin
        },
        filesToShow () {
            return this.lastSearchPhrase ? this.foundItems : this.files
        },
        isValueFoundInFiles () {
            return (
                this.localValue === this.files.find(file => file.path === this.localValue)?.path ||
                this.localValue === this.foundItems.find(file => file.path === this.localValue)?.path
            )
        }
    },
    created () {
        this.localValue = this.value
        this.initialFetch()
    },
    methods: {
        getFileType(path) {
            const curExtention = path.split('.').pop()
            const types = {
                image: ['png', 'jpg', 'jpeg'],
                audio: ['mp3']
            }
            for (const key in types) {
                const extentions = types[key]
                
                if (extentions.includes(curExtention)) {
                    return key
                }
            }
            return 'other'
        },
        returnPath (val) {
            this.$emit('input', val)
        },
        closeDialog () {
            this.dialog = false
        },
        async initialFetch () {
            // Делаем первый общий запрос
            await this.fetchFiles()
            // Дополнительно ищем все, что подходит под предустановленный путь
            if (this.localValue) {
                await this.onSearch(this.localValue)
                // Очищаем данные поиска
                this.foundItems = []
                this.lastSearchPhrase = ''
            }
        },
        async onSearch (searchPhrase) {
            this.lastSearchPhrase = searchPhrase

            if (!searchPhrase) { return }
            const items = []
            let promises

            if (searchPhrase.includes('/')) {
                promises = [ this.$store.dispatch('media_library/search', { path: searchPhrase }) ]
            } else {
                promises = [
                    this.$store.dispatch('media_library/search', { title: searchPhrase, path: this.path, type: this.type }), // search by title
                    this.$store.dispatch('media_library/search', { path: `${this.path}/${searchPhrase}`, type: this.type }) // search by path
                ]
            }
            const results = await Promise.all(promises)

            results.forEach(result => {
                if (result.error) {
                    console.error(result.error)
                    return
                }
                items.push(...result.data.items.map(item => {
                        item.id = parseInt(item.id)
                        return item
                    })
                )
            })
            this.foundItems = _.uniqBy(items, 'id')
            
            // Объединяем массивы, чтобы найденные данные попали this.files
            this.addNewItemsToList(this.foundItems)
        },
        // Добавляем только уникальные файлы в список
        addNewItemsToList (items) {
            let hasChanges = false
            for (const item of items) {
                const foundCopy = this.files.find(file => file.id === item.id)
                if (!foundCopy) {
                    item.id = parseInt(item.id)
                    this.files.push(item)
                    hasChanges = true
                }
            }
            return hasChanges
        },
        async fetchFiles () {
            if (this.loading) { return }
            this.loading = true

            try {
                const response = await this.$store.dispatch('media_library/media', {
                    limit: 20,
                    offset: this.files.length,
                    path: this.path,
                    type: this.type
                });
                if (response.success) {
                    const isDataUpdated = this.addNewItemsToList(response.data.items)
                    this.showUploadMoreButton = isDataUpdated
                } else {
                    console.error('Ошибка загрузки данных медиа-библиотеки.')
                }
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="sass">
.media-lib__preview-image
    width: auto
    max-width: 100%
    border: 1px solid #ccc
    border-radius: 8px
    &.media-lib__preview-image--small-square
        height: 100%
        max-width: 50px
        max-height: 50px
</style>