<template>
    <v-form>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-text-field 
            v-model="$v.form.name.$model"
            :error-messages="getErrors('form.name')"
            autocomplete="new-email"
            label="Название" 
            name="name" 
            type="text" 
            placeholder=" "
            :color="$const.color.primary" 
        ></v-text-field>

        <v-select
          v-model="$v.form.subject.$model"
          :error-messages="getErrors('form.subject')"
          required
          :items="subjects"
          label="Предмет"
        ></v-select>

        <v-select
          v-model.number="$v.form.grade.$model"
          :error-messages="getErrors('form.grade')"
          required
          :items="grades"
          label="Класс"
        ></v-select>

        <v-text-field 
            v-model="$v.form.option.$model"
            :error-messages="getErrors('form.option')"
            label="Вариант" 
            name="option"
            type="number"
            :color="$const.color.primary" 
        ></v-text-field>

        <!-- <v-select
            v-model.number="$v.form.is_final.$model"
            :error-messages="getErrors('form.is_final')"
            required
            :items="[{text: 'Нет', value: false}, {text: 'Да', value: true}]"
            label="Итоговая работа"
        ></v-select> -->

        <v-label>Источник работы (для печати)</v-label>
        <media-library
            v-model="$v.form.source.$model"
            type="pdf"
            class="mb-5 mt-1"
        />

        <v-label>Инструменты</v-label>
        <v-card class="d-flex align-center pa-3 mb-5 mt-1">
            <template v-if="form.tools">
                <v-checkbox
                    v-model="form.tools.triangle"
                    label="Треугольник"
                    hide-details
                    class="mr-5 my-0"
                />
                <v-checkbox
                    :input-value="form.tools.protractor"
                    label="Транспортир"
                    hide-details
                    class="mr-5 my-0"
                    @change="onCheckboxChanged('form.tools.protractor', $event)"
                />
            </template>

            <v-select
                v-model="form.hint_id"
                :items="hints"
                item-text="name"
                item-value="id"
                label="Подсказка"
                hide-details
                clearable
                style="max-width: 300px"
                class="py-0 my-0"
            ></v-select>
        </v-card>
        <v-data-table
            v-model="selectedTasks"
            dense
            disable-sort
            disable-pagination
            disable-filtering
            hide-default-footer 
            :calculate-widths="true"
            :headers="[
                { text: 'Номер задания', value: 'task', width: 1 },
                { text: 'Вариант', value: 'option', width: 1 },
                // { text: 'Итоговая работа', value: 'is_final_ru', width: 1 },
                // { text: '', value: 'btn_toggle', width: 1 }
            ]"
            :items="tasks"
            show-select
            checkbox-color="#E7EEF4"
            item-key="id"
            height="25vh"
            class="elevation-0"
            >
        </v-data-table>

        <template v-if="lockingInteractivesAmongSelectedTasks.length">
            <v-divider class="my-4" />

            <v-label>Настройки блокировки заданий</v-label>

            <v-alert
                v-if="!form.id"
                dense
                type="error"
            >
                Эта область настроек будет доступна после сохранения работы
            </v-alert>
            
            <v-card v-else class="d-flex flex-column pa-3 my-4">

                <div
                    v-for="item in lockingInteractivesAmongSelectedTasks"
                    :key="item.task.id"
                    class="d-flex flex-column"
                >
                    <span class="mb-2">Задание №{{ item.task.number }}</span>
                    <div
                        v-for="type in item.inputTypes"
                        :key="type"
                        class="d-flex align-center w-100 pl-5"
                    >
                        <span style="width: 50%">Интерактив <strong>{{ $const.editorBlockTypes[type] }}</strong></span>
                        <div style="width: 50%">
                            <v-autocomplete
                                :key="selectedTasks.length"
                                v-model="lockedTasksToKey[`${type}:${item.task.id}`]"
                                :items="selectedTasksList"
                                outlined
                                hide-details
                                dense
                                small-chips
                                multiple
                                label="Блокируемые задания"
                            ></v-autocomplete>
                        </div>
                    </div>
                </div>
            </v-card>
            
        </template>
        
        <v-divider class="my-4" />

        <action-buttons 
            :waiting-save-and-back="waiting.save.back"
            no-save-and-update
            @back="back"
            @save="save"
        />
    </v-form>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ActionButtons from '@/components/crud/ActionButtons.vue'
import MediaLibrary from '@/components/inputs/media-library'

const getDefaultTools = () => ({
    triangle: null,
    protractor: null
})
const blockingInteractives = ['audio-dictation'];

export default {
    name: 'JobForm',
    components: { ActionButtons, MediaLibrary },
    props: {
        model: {
            type: Object
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data() {
        return {
            storeModule: 'job',
            tasks: [],
            selectedTasks: [],
            hints: [],
            fetchedLockedTasks: [],
            lockedTasksToKey: {},
            form: {
                name: '',
                subject: '',
                source: null,
                category: 0,
                grade: null,
                option: 1,
                task_id: null,
                // is_final: false,
                hint_id: null,
                tools: getDefaultTools()
            }
        };
    },
    computed: {
        ...mapState('job', ['subjects']),
        ...mapGetters('job', ['grades']),
        selectedTasksList () {
            return this.selectedTasks.map(task => ({
                text: `Номер ${task.task}`,
                value: task.id
            }))
        },
        lockingInteractivesAmongSelectedTasks () {
            return this.selectedTasks.map(task => {
                        const data = typeof task.data === 'string' ? JSON.parse(task.data) : task.data;
                        const inputTypes = data.subtasks.map(subtask => subtask.inputs.map(input => input.type))?.flat()
                        return {
                                task: this.selectedTasks.find(selected => selected.id === task.id),
                                inputTypes: Array.from(new Set( inputTypes
                                                                    .filter(type => blockingInteractives.includes(type))
                                                                )
                                                    )
                            }
                    })
                    .filter(task => task.inputTypes.length)
        }
    },
    validations() {
        return {
            form: {
                name: { required },
                subject: { required },
                grade: { required },
                task_id: { required },
                option: { required },
                // is_final: {},
                source: {},
                tools: {},
                category: {},
                hint_id: {}
            }
        }
    },
    watch: {
        'form.subject'() {
            this.fetchTasks();
        },
        'form.grade'() {
            this.fetchTasks();
        },
        // 'form.is_final'() {
        //     this.fetchTasks();
        // },
        tasks: {
            handler () {
                if (!(this.isUpdate && this.form.task_id?.length)) { return; }
                this.selectedTasks = this.tasks.filter(task => this.form.task_id?.includes(task.id));
            },
            deep: true
        },
        selectedTasks(val) {
            this.form.task_id = _.map(val, 'id') || null;
        }
    },
    created () {
        this.fetchHints();
        this.fetchLockedTasks();

        if (this.isUpdate) {
            if (this.form.grade)
                this.form.grade = parseInt(this.form.grade);

            this.form.task_id = _.uniq(this.form.task_id || []);

            if (typeof this.form.tools === 'string')
                this.form.tools = JSON.parse(this.form.tools);

            if (!this.form.tools)
                this.form.tools = getDefaultTools();

            if (this.form.tasks.length)
                this.form.task_id = this.form.tasks.map(task => task.id);
        }
    },
    methods: {
        onCheckboxChanged (field, value) {
            _.set(this, field, value ? true : null)
        },
        prepareForm (form) {
            return {
                ...form,
                task_id: _.uniq(form.task_id), // remove duplicates
                tools: JSON.stringify(form.tools)
            }
        },
        async beforeSave () {
            if (!this.form.id) { return; }

            const promises = [];
            const payloads = [];

            Object.keys(this.lockedTasksToKey).forEach(key => {
                
                this.lockedTasksToKey[key].forEach(taskId => {
                    
                    payloads.push({
                        job_id: this.form.id,
                        task_id: taskId,
                        unlock_key: key
                    });
                });
            });
            const itemsToDelete = this.fetchedLockedTasks.filter(fetchedItem => !payloads.find(payload => fetchedItem.task_id === payload.task_id && fetchedItem.unlock_key));
            
            itemsToDelete.forEach(item => {
                
                promises.push( this.$store.dispatch(`locked_tasks_of_job/delete`, item) );
            });

            payloads.forEach(item => {
                
                promises.push( this.$store.dispatch(`locked_tasks_of_job/upsert`, item) );
            })

            await Promise.all(promises);
        },
        async fetchLockedTasks () {
            if (!this.form.id) { return; }
            const { data } = await this.$store.dispatch(`locked_tasks_of_job/list`, {
                pagination: 0,
                fields: 'job_id,task_id,unlock_key',
                filter: { job_id: this.form.id },
                sort: { job_id: 'ASC' }
            });
            this.fetchedLockedTasks = data?.items || [];

            this.fetchedLockedTasks.map(item => {
                if (!Array.isArray(this.lockedTasksToKey[item.unlock_key])) {
                    this.lockedTasksToKey[item.unlock_key] = [];
                }
                this.lockedTasksToKey[item.unlock_key].push(item.task_id);
            })
        },
        async fetchHints() {
            const { data } = await this.$store.dispatch(`hint/list`, {
                filter: { type: 'job-hint' },
                pagination: 0
            });
        
            this.hints = _.map(_.get(data, 'items', []), (o,k) => {
                o['number'] = k + 1;
                return o;
            }) || [];
        },
        async fetchTasks() {
            if(_.size(this.form.subject) > 0 && !_.isNil(this.form.grade))
            {
                const { data } = await this.$store.dispatch(`task/list`, {
                    pagination: 0,
                    filter: {
                        subject: this.form.subject,
                        grade: this.form.grade || 0,
                        // is_final: this.form.is_final || 0
                    }
                });
            
                this.tasks = _.map(_.get(data, 'items', []), (o,k) => {
                                    o.number = k + 1;
                                    // o.is_final_ru = o.is_final ? 'Да' : 'Нет'
                                    return o;
                                })
                            .sort((a, b) => {
                                const aValue = this.form.task_id?.includes(a.id) ? 1 : 0;
                                const bValue = this.form.task_id?.includes(b.id) ? 1 : 0;
                                return bValue - aValue;
                            });
            }
            else
                this.tasks = [];
        }
    }
}
</script>