<template>
  <v-card>
    <v-toolbar
        dark
        :flat="!windowView"
        :color="windowView ? 'primary': 'transparent'"
    >
        <template v-if="windowView">
            <v-toolbar-title>Медиа библиотека</v-toolbar-title>
            <span class="ml-4 mt-1">Тип файлов: {{label}}</span>
        </template>
        <v-btn
            elevation="0"
            color="blue"
            :class="{'ml-4': windowView}"
            :disabled="isLoadingProcess"
            @click="openFileUploadWindow('fileInput')"
        >Загрузить файл</v-btn>
        <input
            :accept="acceptRules"
            ref="fileInput"
            type="file"
            multiple="multiple"
            class="d-none"
            @change="onFileInputChange"
        />
        <input
            :accept="acceptRules"
            ref="fileInputForReplace"
            type="file"
            class="d-none"
            @change="onFileInputForReplaceChange"
        />

        <v-spacer></v-spacer>
        <v-btn
            v-if="windowView"
            icon
            dark
            @click="closeDialog"
        >
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-toolbar>

    <v-container fluid class="media-lib-content pa-4">
        <v-row>
            <v-col cols="8">
                <v-alert v-if="serverErrors" type="error">
                    <span
                        v-for="(error, index) in serverErrors"
                        :key="index"
                    >{{error.type || error}}</span>
                </v-alert>

                <v-text-field
                    v-model="searchText"
                    label="Фильтр по названию или пути"
                    clearable
                    @input="pickedFile = null"
                ></v-text-field>

                <v-list :disabled="isLoadingProcess">
                    <div class="media-lib__items-list">
                        <v-list-item
                            v-for="file in filesToShow"
                            :key="file.id"
                            class="file-item"
                            :class="{active: pickedFile === file}"
                            @click="pickFile(file)"
                        >
                            <v-list-item-avatar
                                color="primary lighten-2"
                                size="56"
                            >
                                <v-icon dark>mdi-file-outline</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{ file.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-btn
                            v-if="!searchText && showUploadMoreButton"
                            elevation="0"
                            color="blue"
                            dark
                            class="d-block mx-auto mt-5"
                            :disabled="isLoadingProcess"
                            @click="() => { $emit ('uploaded') }"
                        >
                            Загрузить ещё <v-icon class="ml-2">mdi-cloud-download-outline</v-icon>
                        </v-btn>
                    </div>
                </v-list>
            </v-col>
            <v-col cols="4" class="media-lib__preview-zone">
                <div v-if="pickedFile">

                    <div class="mb-4">
                        <img v-if="getFileType(pickedFile.path) === 'image'" class="media-lib__preview-image" :src="`${origin}/${pickedFile.path}?${pickedFile.id}`">
                        <audio v-else-if="getFileType(pickedFile.path) === 'audio'" :src="`${origin}/${pickedFile.path}?${pickedFile.id}`" controls></audio>
                        <iframe
                            v-else-if="getFileType(pickedFile.path) === 'document'"
                            :src="`${origin}/${pickedFile.path}?${pickedFile.id}`"
                            height="300px"
                            width="100%"
                            frameborder="0"
                        ></iframe>
                    </div>

                    <div class="media-lib__scrollable-line mb-4">
                        <strong>Название: </strong><span>{{pickedFile.title}}</span>
                    </div>

                    <div class="media-lib__scrollable-line mb-4">
                        <strong>Путь: </strong><span>{{pickedFile.path}}</span>
                    </div>

                    <v-btn
                        v-if="!disableSelect"
                        elevation="0"
                        color="success"
                        :loading="isLoadingProcess"
                        :disabled="isLoadingProcess"
                        @click="returnPath(pickedFile.path)"
                    >Выбрать</v-btn>

                    <v-btn
                        v-if="removeAvailable"
                        elevation="0"
                        color="error"
                        :loading="isLoadingProcess"
                        :disabled="isLoadingProcess"
                        @click="remove(pickedFile.id)"
                    >Удалить</v-btn>
                    <v-btn
                        v-if="removeAvailable"
                        elevation="0"
                        color="warning"
                        :loading="isLoadingProcess"
                        :disabled="isLoadingProcess"
                        class="ml-4"
                        @click="openFileUploadWindow('fileInputForReplace')"
                    >Заменить</v-btn>
                </div>
                <div v-else>
                    <v-alert type="info" class="text-center mt-10">
                        Для отображения информации о файле выберите его из списка.
                    </v-alert>
                </div>
            </v-col>
        </v-row>
    </v-container>
</v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { errorMixin, saveMixin } from '@/mixins/formMixin'

export default {
    props: {
        value: { type: Boolean, default: false },
        pickedFilePath: { type: String, default: null },
        files: { type: Array, default: () => [] },
        loading: { type: Boolean, default: false },
        label: { type: String, default: 'Файл' },
        showUploadMoreButton: { type: Boolean, default: true },
        path: { type: String, default: '' },
        windowView: { type: Boolean, default: false },
        disableSelect: { type: Boolean, default: false },
        removeAvailable: { type: Boolean, default: false },
        type: { type: String, default: null }
    },
    mixins: [ validationMixin, errorMixin, saveMixin ],
    data () {
        return {
            pickedFile: null,
            searchText: '',
            waiting: false,
            serverErrors: null,
        }
    },
    computed: {
        origin () {
            return window.location.origin
        },
        maxWeightToUpload () {
            return 20000000 // 20MB
        },
        isLoadingProcess () {
            return this.waiting || this.loading
        },
        filesToShow () {
            return _.orderBy(this.files, ['id'], ['desc']).filter(file => file?.path?.endsWith(this.type || ''))
        },
        availableFileExt () {
            return ['.mp3','.png','.jpg','.jpeg','.pdf','.doc','.docx']
        },
        acceptRules () {
            return this.availableFileExt.filter(ext => !this.type ? true : this.type === ext.replace('.', '')).join(',')
        }
    },
    watch: {
        searchText () {
            this.searchText ? this.debounceSearch() : this.$emit('search', this.searchText)
        },
        pickedFilePath () {
            const file = this.files.find(f => f.path === this.pickedFilePath) || null
            this.pickFile(file, false)
        }
    },
    created () {
        const file = this.files.find(f => f.path === this.pickedFilePath) || null
        this.pickFile(file, false)
    },
    methods: {
        getFileType (path) {
            const curExtention = path.split('.').pop()
            const types = {
                image: ['png', 'jpg', 'jpeg'],
                audio: ['mp3'],
                document: ['pdf']
            }
            for (const key in types) {
                const extentions = types[key]
                
                if (extentions.includes(curExtention)) {
                    return key
                }
            }
            return 'other'
        },
        debounceSearch: _.debounce(function () {
            this.$emit('search', this.searchText)
        }, 750),
        async onFileInputChange () {
            const files = this.$refs.fileInput.files

            if (files?.length) {
                if (files.length > 1) {
                    const result = await Promise.all([...files].map(file => this.uploadFile(file)));
                    alert(result ? 'Все файлы загружены успешно.' : 'Загрузка завершена с ошибками.')
                } else {
                    this.uploadFile(files[0]);
                }
            }

            this.$refs.fileInput.value = null
        },
        async onFileInputForReplaceChange () {
            const files = this.$refs.fileInputForReplace.files
            if (!files?.length) { return false }
            const filename = this.pickedFile.path.split('/').pop()
            const result = await this.remove(this.pickedFile.id, false)
            if (result) {
                await this.uploadFile(files[0], filename)
            } else {
                alert('Ошибка удаления файла.')
            }
            this.$refs.fileInputForReplace.value = null
        },
        openFileUploadWindow (ref) {
            this.$refs[ref]?.click()
        },
        pickFile (file, update = true) {
            this.pickedFile = file
            update && this.$emit('update:pickedFilePath', file.path)
        },
        returnPath (value) {
            this.$emit('onFileSelect', value)
            this.closeDialog()
        },
        closeDialog () {
            this.$emit('input', false)
        },
        async remove (id, shouldConfirm = true) {
            if (shouldConfirm) {
                const result = confirm('Подтвердите удаление файла')
                if (!result) { return }
            }
            this.waiting = true
            this.serverErrors = null

            const { success, error } = await this.$store.dispatch('media_library/delete', { id })

            if(!success) { this.serverErrors = error }
            else {
                this.$emit('fileRemoved', id)
                this.pickedFile = null
            }

            this.waiting = false
            return !!success
        },
        async uploadFile (file, filename = null) {
            if (file?.size < this.maxWeightToUpload) {
                this.waiting = true
                this.serverErrors = null

                let form = new FormData();
                form.append('file', file);
                filename && form.append('filename', filename);
                form.append('path', this.path.replace(/^\//gm, '').replace(/\/$/gm, ''));

                const { success, error, data } = await this.$store.dispatch('media_library/upload', form)

                this.waiting = false
                if(!success) {
                    this.serverErrors = error
                    return false
                }
                else {
                    this.$emit('add-new-items-to-list', [data.insertedItem])
                    return true
                }
            }
            
            this.serverErrors = `Ошибка: Переданный файл "${file.name}" не валиден или превышает ${Math.ceil(this.maxWeightToUpload / 1024 / 1024)}MB`
            return false
        }
    }
}
</script>

<style lang="sass" scoped>
.file-item
    cursor: pointer

.file-item:hover, .file-item.active
    background-color: seashell

.media-lib__items-list
    overflow-y: scroll
    height: 75vh

.media-lib__preview-zone
    border-left: 1px solid #ccc

.media-lib__scrollable-line
    width: 100%
    display: flex
    flex-direction: row
    background-color: #ccc
    border-radius: 8px
    padding: 10px
    & strong
        margin-right: 16px
    & span
        overflow-x: scroll
        white-space: nowrap

.media-lib__preview-image
    width: auto
    max-width: 100%
</style>